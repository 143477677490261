import { useApi } from '@/api';
import { Divider, Page } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import { useSearchParams } from '@/hooks';
import { generateMarketName } from '@/utils/marketNameUtil';
import { Box, CryptoIcon, Flex } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import ChartTabsLong from './components/ChartTabsLong';
import HoldersDonutChart from './components/HoldersDonutChart';
import MarketActivityHeatmap from './components/MarketActivityHeatmap';
import PositionList from './components/PositionList';

export default function LongPage() {
  const { marketId } = useParams();
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [data, error] = useApi({
    url: `/markets/${marketId}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const titleText = generateMarketName(
      data.asset_symbol,
      data.yield_name,
      data.maturity_time
    );
    const title = (
      <>
        <Flex gap="space-3xs" alignItems={'center'}>
          <DoubleSymbol
            symbol1={
              <CryptoIcon name={data.asset_symbol} size={40} extension="png" />
            }
            symbol2={
              <CryptoIcon
                name={data.yield_source ? data.yield_source : ''}
                size={40}
                extension="png"
              />
            }
          />
          <h2>{titleText} Long</h2>
        </Flex>
      </>
    );
    return (
      <Page title={title}>
        <Divider />
        <Box flexBasis={['100%', '100%', '100%']}>
          <ChartTabsLong currency={data.asset_symbol} daysAgo={daysAgo} />
        </Box>
        <Divider />
        <Flex gap="space-xs" direction={['row', 'column', 'column']}>
          <Box flexBasis={['67%', '100%', '100%']}>
            <PositionList marketId={marketId} positionType={'long'} />
          </Box>
          <Box flexBasis={['33%', '100%', '100%']}>
            <HoldersDonutChart marketId={marketId} positionType={'long'} />
          </Box>
        </Flex>
        <Divider />
        <Box flexBasis={['100%', '100%', '100%']}>
          <MarketActivityHeatmap marketId={marketId} positionType={'long'} />
        </Box>
      </Page>
    );
  }

  return null;
}
