import { useApi } from '@/api';
import { Link } from '@/components';
import {
  CardLoader,
  CurrencyValue,
  DoughnutChart,
  EmptyBox,
  Flex,
  chartsRgbColorPallete,
  formatPercentage,
  textShortener
} from '@blockanalitica/ui';
import ReactDOMServer from 'react-dom/server';

export default function HoldersDonutChart({ marketId, positionType }) {
  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/share/`,
    queryParams: {
      type: positionType
    }
  });

  if (error) {
    if (error.status === 404) {
      return <EmptyBox />;
    } else {
      throw new Error(error);
    }
  }
  const renderCustomTooltip = (context) => {
    const tooltipEl = document.getElementById('chartjs-tooltip');

    if (context.tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    const dataIndex = context.tooltip.dataPoints[0].dataIndex;
    const walletAddress = data[dataIndex].wallet_address;
    const percentShare = formatPercentage(data[dataIndex].share);
    const balance = data[dataIndex].balance;
    const asset_symbol = data[dataIndex].asset_symbol;
    const content = ReactDOMServer.renderToString(
      <Flex
        direction="column"
        gap="space-3xs"
        style={{
          padding: '10px',
          background: 'rgba(0, 0, 0, 0.7)',
          borderRadius: '4px',
          color: 'white'
        }}>
        <div style={{ fontWeight: 'bold' }} className="text-2">
          {textShortener(walletAddress)}
        </div>
        <Flex alignItems="center" gap="space-3xs" className="text-2">
          <span>Balance:</span>
          <CurrencyValue
            value={balance}
            options={{
              currency: `${asset_symbol}`,
              iconSize: 'xSmall',
              leadingCurrencySymbol: false
            }}
          />
        </Flex>
        <div className="text-2">{`Share: ${percentShare}`}</div>
      </Flex>
    );

    tooltipEl.innerHTML = content;

    const canvasRect = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
      canvasRect.left + window.pageXOffset + context.tooltip.caretX + 'px';
    tooltipEl.style.top =
      canvasRect.top + window.pageYOffset + context.tooltip.caretY + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.transform = 'translate(-50%, -100%)';
  };

  if (data) {
    const colors = chartsRgbColorPallete(1);
    const customConfig = {
      options: {
        plugins: {
          tooltip: {
            enabled: false,
            external: renderCustomTooltip
          }
        }
      }
    };

    const chartData = {
      labels: data.map((row) => textShortener(row.wallet_address)),
      datasets: [
        {
          data: data.map((row) => row.share),
          backgroundColor: colors.map((color) => color),
          borderColor: colors.map((color) => color)
        }
      ]
    };

    return (
      <Flex direction="column" gap="space-xs" width={'100%'}>
        <Flex justifyContent="flex-end" gap="space-xs" alignItems="center">
          <Link
            daysAgo={true}
            to={`/markets/${marketId}/positions/?tab=${positionType}`}>
            view all
          </Link>
        </Flex>
        <div style={{ paddingTop: 'var(--size-0)' }}>
          <DoughnutChart data={chartData} config={customConfig} />
        </div>
        <div id="chartjs-tooltip" />
      </Flex>
    );
  }
  return <CardLoader />;
}
