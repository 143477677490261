import { useApi } from '@/api';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import {
  CurrencySwitchBarChart,
  CurrencySwitchLineChart
} from '@/components/charts';
import { useSearchParams } from '@/hooks';
import {
  CardLoader,
  CurrencyFilter,
  Flex,
  useTableProps,
  useThemeCustomProperties
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function ChartTabsLP({ daysAgo, currency }) {
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'liquidity'
  );
  const { marketId } = useParams();
  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const displayOptions = [
    { key: 'liquidity', value: 'Liquidity' },
    { key: 'lpPrice', value: 'LP price' },
    { key: 'volume', value: 'Trading Volume' },
    { key: 'openClose', value: 'Add/Remove' }
  ];

  const [data, error] = useApi({
    url: `/markets/${marketId}/snapshots/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  const colors = useThemeCustomProperties([
    'color-primary',
    'color-open',
    'color-close'
  ]);
  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  const switchValue = filters.filtersApplied.currency;
  const tabsContent = useMemo(
    () => ({
      liquidity: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: 'liquidity',
              label: 'Liquidity'
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{ dynamic: true }}
        />
      ),
      volume: (
        <CurrencySwitchBarChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: `volume_lp`,
              label: 'Trading Volume',
              backgroundColor: colors.colorPrimary
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          options={{ dynamic: true }}
          daysAgo={daysAgo}
        />
      ),
      lpPrice: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: 'lp_share_price',
              label: 'Lp price'
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          kpiFractionDigits={5}
          options={{ dynamic: true }}
        />
      ),
      openClose: (
        <CurrencySwitchBarChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: `volume_add_lp`,
              label: 'Add',
              backgroundColor: colors.colorOpen
            },
            {
              field: `volume_remove_lp`,
              label: 'Remove',
              backgroundColor: colors.colorClose
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{
            mode: 'index',
            plugins: false,
            dynamic: true
          }}
        />
      )
    }),
    [data, currency, daysAgo, activeDisplayOption, colors, switchValue]
  );

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Flex direction="column" gap="space-m">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'Token' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>

        {tabsContent[activeDisplayOption]}
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
