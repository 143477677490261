import { useApi } from '@/api';
import { Page } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import KpiPercentageCard from '@/components/Kpi/KpiPercentageCard';
import { useSearchParams } from '@/hooks';

import { CryptoIcon, Flex, PercentageValue } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import ChartTabs from './ChartTabs';

export default function FRBMarketPage() {
  const { marketUid } = useParams();
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [data, error] = useApi({
    url: `/fixed-rate-borrow/markets/${marketUid}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <Flex justifyContent={'space-between'}>
            <Flex gap="space-3xs">
              <CryptoIcon name={'MORPHO'} size={40} />
              <DoubleSymbol
                symbol1={
                  <CryptoIcon name={data.collateral_token_symbol} size={40} />
                }
                symbol2={<CryptoIcon name={data.loan_token_symbol} size={40} />}
              />
              <Flex direction="column">
                <h2>
                  {data.collateral_token_symbol}/{data.loan_token_symbol}
                </h2>
                <Flex className="text-1 text-muted" gap="space-3xs">
                  LLTV: <PercentageValue value={data.lltv} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        }>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title={'Coverage Capacity'}
              data={data}
              field="coverage_capacity"
              currency={data.loan_token_symbol}
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                iconSize: 'large'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiPercentageCard
              title="Current Morpho Borrow Rate"
              data={data}
              field="borrow_rate_apy"
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                fractionDigits: 2
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiPercentageCard
              title="Projected Max Fixed Borrow Rate"
              data={data}
              field="max_fixed_borrow_rate"
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                fractionDigits: 2
              }}
            />
          </Flex>
        </Flex>

        <ChartTabs daysAgo={daysAgo} />
      </Page>
    );
  }
  return null;
}
