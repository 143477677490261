import { useApi } from '@/api';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { PercentageLineChart } from '@/components/charts';
import { useSearchParams } from '@/hooks';
import {
  CardLoader,
  Flex,
  useTableProps,
  useThemeCustomProperties
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function ChartTabs({ daysAgo }) {
  const { marketUid } = useParams();
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'borrow_rate_apy'
  );
  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );
  const colors = useThemeCustomProperties([
    'color-primary',
    'color-variable',
    'color-short',
    'color-lp'
  ]);

  const [data, error] = useApi({
    url: `/fixed-rate-borrow/markets/${marketUid}/historic/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  const switchValue = filters.filtersApplied.currency;

  const tabsContent = useMemo(
    () => ({
      borrow_rate_apy: (
        <PercentageLineChart
          key={activeDisplayOption + switchValue}
          data={data}
          fields={[
            {
              field: 'borrow_rate_apy',
              label: 'Morpho Borrow Rate',
              backgroundColor: colors.colorPrimary
            }
          ]}
          aspectRatio={6}
          daysAgo={daysAgo}
        />
      ),
      max_fixed_borrow_rate: (
        <PercentageLineChart
          key={activeDisplayOption + switchValue}
          data={data}
          fields={[
            {
              field: 'max_fixed_borrow_rate',
              label: 'Fixed Borrow Rate',
              backgroundColor: colors.colorPrimary
            }
          ]}
          aspectRatio={6}
          daysAgo={daysAgo}
        />
      )
    }),
    [activeDisplayOption, data, colors, daysAgo, switchValue]
  );

  if (error) {
    throw error;
  }

  if (data) {
    const displayOptions = [
      {
        key: 'borrow_rate_apy',
        value: 'Morpho Borrow Rate'
      },
      {
        key: 'max_fixed_borrow_rate',
        value: 'Fixed Borrow Rate'
      }
    ];

    return (
      <Flex direction="column" gap="space-xs">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
        </Flex>

        {tabsContent[activeDisplayOption]}
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
