import { useMemo } from 'react'; // Import useMemo from react
import { useApi } from '@/api';
import SelectFilter from '@/components/filters/SelectFilter';

type MarketData = {
  asset_symbol: string;
  maturity_time: number;
  address: string;
}[];

export default function SelectMarket({ pagination, filters }) {
  const [data, error] = useApi({
    url: `/filters/select-market/`
  });

  const selectFilterOptions = useMemo(() => {
    const options = [{ label: 'All markets', value: '' }];

    const results = data as MarketData;
    if (results && results.length > 0) {
      options.push(
        ...results.map((item) => ({
          label: `${item.asset_symbol} ${item.maturity_time}d`,
          value: item.address
        }))
      );
    }

    return options;
  }, [data]);

  if (error) {
    return <div>Error loading data...</div>;
  }

  return (
    <SelectFilter
      key="market-filter"
      placeholder="Showing all markets"
      filterField="marketAddress"
      options={selectFilterOptions}
      onPageChange={pagination.onPageChange}
      {...filters}
    />
  );
}
