import { useApi } from '@/api';
import { Link } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import Table from '@/components/table/Table';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  RelativeDateTime,
  WalletAddress,
  useTableProps
} from '@blockanalitica/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
export default function PositionsTable({ daysAgo, type, filters }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 5,
    initialSorting: '-balance_usd'
  });
  const { marketId, walletAddress } = useParams();
  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/${walletAddress}/`,
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting,
      type: type,
      position_status: filters.filtersApplied.state
    }
  });
  const titleOptions = useMemo(() => {
    if (filters.filtersApplied.state == 'open') {
      return {
        currentValue: 'CURRENT VALUE',
        positionSize: type.toUpperCase() + ' SIZE',
        lastActivity: 'LATEST ACTIVITY'
      };
    } else {
      return {
        currentValue: 'CLOSING VALUE',
        positionSize: 'BOND AMOUNT CLOSED',
        lastActivity: 'CLOSED'
      };
    }
  }, [filters, type]);
  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        )
      },
      {
        header: 'MARKET',
        getCell: (row) => (
          <Flex gap="space-3xs" alignItems="center">
            <DoubleSymbol
              symbol1={<CryptoIcon name={row.asset_symbol} extension="png" />}
              symbol2={
                <CryptoIcon
                  name={row.yield_source ? row.yield_source : ''}
                  extension="png"
                />
              }
            />
            {row.asset_symbol} {row.market_maturity_time / 86400}d
          </Flex>
        )
      },
      {
        header: titleOptions.currentValue,
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.balance_usd}
                options={{ currency: 'USD' }}
              />
            ) : (
              <CurrencyValue
                value={row.balance}
                options={{
                  dynamic: false,
                  currency: row.asset_symbol,
                  leadingCurrencySymbol: false,
                  iconOpacity: 0.7,
                  iconSize: 'xSmall'
                }}
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.balance_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.balance_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'balance'
      },
      {
        header: titleOptions.positionSize,
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.bond_balance_usd}
                options={{ currency: 'USD' }}
              />
            ) : (
              <CurrencyValue
                value={row.bond_balance}
                options={{
                  dynamic: false,
                  currency: row.asset_symbol,
                  leadingCurrencySymbol: false,
                  iconOpacity: 0.7,
                  iconSize: 'xSmall'
                }}
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.bond_balance_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.bond_balance_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'bond_balance'
      }
    ];

    if (filters.filtersApplied.state === 'open') {
      columns.push({
        header: 'MATURITY TIME',
        getCell: (row) => <RelativeDateTime value={row.maturity_time} />,
        getCellBottom: null,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'maturity_time'
      });
    }

    columns.push({
      header: titleOptions.lastActivity,
      getCell: (row) => <RelativeDateTime value={row.last_activity} />,
      getCellBottom: null,
      cellAlign: 'flex-end',
      headerAlign: 'flex-end',
      sortingField: 'last_activity'
    });

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.wallet_address}
        getLinkWrapper={(row) => (
          <Link daysAgo={true} to={`${type}?asset_id=${row.asset_id}`} />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return null;
}
