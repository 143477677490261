import { useApi } from '@/api';
import { Heatmap } from '@/components/charts';
import { LinkButton } from '@/components/forms';
import { CardLoader, Flex, useIsMobile } from '@blockanalitica/ui';
import { useNavigate, useParams } from 'react-router-dom';

export default function MarketActivityHeatmap({ positionType }) {
  const isMobile = useIsMobile();
  const monthsAgo = isMobile ? 3 : 12;

  const { marketId, network } = useParams();

  let [data] = useApi({
    url: `/markets/${marketId}/activity/`,
    queryParams: {
      type: positionType
    }
  });

  const navigate = useNavigate();

  function onClickCallback(
    //@ts-ignore
    e,
    { date }
  ) {
    navigate(
      `/${network}/markets/${marketId}/events/?date=${date.split('T')[0]}&tab=${positionType}`,
      {
        relative: 'path'
      }
    );
  }

  if (data) {
    return (
      <Flex gap="space-xs" direction="column">
        <Flex gap="space-xs" justifyContent="space-between" alignItems="center">
          <h4>Events Activity</h4>
          <LinkButton
            daysAgo={true}
            to={`/markets/${marketId}/events?tab=${positionType}`}>
            view all
          </LinkButton>
        </Flex>
        <Flex
          direction={['row', 'column', 'column']}
          gap="space-m"
          justifyContent="center">
          <Heatmap
            data={data}
            monthsAgo={monthsAgo}
            onClickCallback={onClickCallback}
          />
        </Flex>
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
