import { useApi } from '@/api';
import { Page } from '@/components';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import { useSearchParams } from '@/hooks';
import { generateMarketName } from '@/utils/marketNameUtil';
import { CryptoIcon, Flex } from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MarketEventsTable from './MarketEventsTable';
import MarketLPEventsTable from './MarketLPEventsTable';
const displayOptions = [
  { key: 'long', value: 'Long' },
  { key: 'short', value: 'Short' },
  { key: 'lp', value: 'LP' }
];

export default function MarketEventsPage() {
  const { marketId } = useParams();
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'long'
  );

  const [data, error] = useApi({
    url: `/markets/${marketId}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const title = useMemo(() => {
    if (data) {
      const titleText = generateMarketName(
        data.asset_symbol,
        data.yield_name,
        data.maturity_time
      );
      return (
        <>
          <Flex gap="space-3xs" alignItems={'center'}>
            <DoubleSymbol
              symbol1={
                <CryptoIcon
                  name={data.asset_symbol}
                  size={40}
                  extension="png"
                />
              }
              symbol2={
                <CryptoIcon
                  name={data.yield_source ? data.yield_source : ''}
                  size={40}
                  extension="png"
                />
              }
            />
            <h2>
              {titleText}{' '}
              {activeDisplayOption.charAt(0).toUpperCase() +
                activeDisplayOption.slice(1)}
            </h2>
          </Flex>
        </>
      );
    }
  }, [data, activeDisplayOption]);
  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const tabsContent = useMemo(
    () => ({
      long: (
        <MarketEventsTable
          key={activeDisplayOption + daysAgo}
          daysAgo={daysAgo}
          activeTab={activeDisplayOption}
          marketId={marketId}
        />
      ),
      short: (
        <MarketEventsTable
          key={activeDisplayOption + daysAgo}
          daysAgo={daysAgo}
          activeTab={activeDisplayOption}
          marketId={marketId}
        />
      ),
      lp: (
        <MarketLPEventsTable
          key={activeDisplayOption + daysAgo}
          daysAgo={daysAgo}
          marketId={marketId}
          activeTab={activeDisplayOption}
        />
      )
    }),
    [activeDisplayOption, daysAgo, marketId]
  );

  return (
    <Page title={title} isTimeSwitch={false}>
      <Flex
        direction={['row', 'row', 'column']}
        alignItems={['flex-start', 'flex-start', 'flex-start']}
        justifyContent="space-between"
        alignSelf={['flex-end', 'flex-end', 'flex-end']}
        gap="space-xs">
        <DisplaySwitch
          options={displayOptions}
          activeOption={activeDisplayOption}
          onChange={handleTabClick}
        />
      </Flex>
      {tabsContent[activeDisplayOption]}
    </Page>
  );
}
