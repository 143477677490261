import { useApi } from '@/api';
import { Card, Divider, Page } from '@/components';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import { useSearchParams } from '@/hooks';
import MarketsTable from '@/pages/components/MarketsTable';
import {
  CryptoIcon,
  CurrencyFilter,
  CurrencyValue,
  Flex,
  WalletAddress,
  useTableProps
} from '@blockanalitica/ui';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

export default function TokensDetailsPage() {
  const [daysAgo] = useSearchParams('days_ago');
  const { asset_address } = useParams();
  const [data, error] = useApi({
    url: `/tokens/${asset_address}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });
  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <Flex direction="row" gap="space-xs">
            <CryptoIcon name={data.asset_symbol} size={40} extension="png" />
            <h1>{data.asset_symbol}</h1>
          </Flex>
        }>
        <Flex direction="column" gap="space-xs">
          <Flex direction={['row', 'column', 'column']} gap="space-xs">
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title="Total Long"
                data={data}
                field="total_long"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
            </Flex>
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title="Total Short"
                data={data}
                field="total_short"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
            </Flex>
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title="Total LP"
                data={data}
                field="total_lp"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
            </Flex>
          </Flex>

          <Flex direction={['row', 'column', 'column']} gap="space-xs">
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title="Total Value Locked"
                data={data}
                field="tvl"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
            </Flex>
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title="# of Markets"
                data={data}
                field="markets"
                currency=""
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2'
                }}
              />
            </Flex>
            <Flex flexBasis={['33%', '100%', '100%']}>
              <Card style={{ width: '100%' }}>
                <Flex
                  direction="column"
                  gap="space-3xs"
                  justifyContent="space-between"
                  style={{ height: '100%' }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <div className={classnames('text-2', 'text-muted')}>
                      Price
                    </div>
                    <Flex direction="column" alignItems="flex-end">
                      <CurrencyValue
                        value={data.asset_price}
                        options={{
                          currency: 'USD',
                          notation: 'compact',
                          dynamic: true,
                          leadingCurrencySymbol: true,
                          FormattedCurrencyOptions: {
                            maximumFractionDigits: 2
                          }
                        }}
                      />
                    </Flex>
                  </Flex>

                  <div>
                    <Flex alignItems="baseline" justifyContent="space-between">
                      <span className={classnames('text-2', 'text-muted')}>
                        Address:
                      </span>
                      <WalletAddress
                        options={{
                          size: 'xSmall',
                          inline: true,
                          showCopyBtn: true,
                          showEthScanLink: true
                        }}
                        address={data.asset_address}
                      />
                    </Flex>
                  </div>
                </Flex>
              </Card>
            </Flex>
          </Flex>
        </Flex>

        <Divider />

        <Flex
          justifyContent="space-between"
          alignContent="center"
          alignItems="center">
          <h4>Markets</h4>
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'Token' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>

        <MarketsTable
          daysAgo={daysAgo}
          filters={filters}
          asset_address={data.asset_address}
        />
      </Page>
    );
  }

  return null;
}
